import React from 'react'
import Header from 'components/Header'
import { FaArrowRight } from 'react-icons/fa'
import Paragraph from 'components/common/paragraph'
import StrokeCareImage from 'assets/images/Services/StrokeCare/StrokeCare.webp'
import { Helmet } from 'react-helmet';

export default function StrokeCare() {
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Stroke Care in Nova Scotia - AmazeCare </title>
        <meta name="description" content="Amaze care has highly trained health workers who are dedicated to caring for stroke victims with dignity and compassion." />
        <meta name="keywords" content="Stroke Care" />
      </Helmet>
      <Header bgcolor="bg-white" title="Stroke Care" />
      <section className="py-20 bg-white text-center md:text-justify">
        <div className="container-layout">
          <div className="flex flex-col lg:flex-row gap-10 items-center">
            <div className="flex-1">
              <Paragraph>
                <p>
                  A stroke happens when a blood artery supplying the brain bursts or when something stops the flow of blood to the brain.
                  It results in death, permanent brain damage, and mobility problems.
                  Any stroke victim is confronted with an immense amount of difficulties that fuel their anxiety and frustration.
                  Activities can also be distressing for the patient&apos;s family.
                </p>
                <p>
                  Amaze care has highly trained health workers who are dedicated to caring for your loved one with dignity and compassion.
                  Our staffs receive training in the special needs of stroke survivors and provides the patient with care that helps
                  them with their daily routine. As part of our specialized care, clients can choose from
                </p>
                <p className="my-2 mx-4">
                  <div className="flex items-baseline space-x-4 py-1">
                    <div className="text-secondary">
                      <FaArrowRight />
                    </div>
                    <span> Nursing care </span>
                  </div>
                  <div className="flex items-baseline space-x-4 py-1">
                    <div className="text-secondary">
                      <FaArrowRight />
                    </div>
                    <span> Recreation therapy </span>
                  </div>
                  <div className="flex items-baseline space-x-4 py-1">
                    <div className="text-secondary">
                      <FaArrowRight />
                    </div>
                    <span> Massage </span>
                  </div>
                </p>
              </Paragraph>
            </div>
            <img
              alt=""
              height={500}
              width={500}
              src={StrokeCareImage}
              className="flex-1 align-middle rounded-lg shadow-2xl"
            />
          </div>
        </div>
      </section>
      <section className="py-20 text-center">
        <div className="container-layout">
          <div className="flex flex-col">
            <Paragraph heading="Peace of Mind for our Clients and Families">
              <p>
                At Amaze care, giving our clients a secure and comfortable environment is our first priority.
                We hope to raise their quality of life by doing this.
                Call now to discuss how our specialist stroke care can benefit your loved one in the comfort of their own home with a Care Manager.
              </p>
            </Paragraph>
          </div>
        </div>
      </section>
    </main>
  );
}
