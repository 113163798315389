import React from 'react'
import {
  FacebookIcon, TwitterIcon, InstagramIcon,
} from 'assets/icons/social-icons'
import {
  HomeIcon, EnvelopeIcon, PhoneIcon,
} from 'assets/icons/contact-icons'
import Paragraph from 'components/common/paragraph'

function Footer() {
  return (
    <footer className="lg:text-left bg-primary">
      <div className="container-layout pt-10 text-center md:text-left">
        <div className="grid grid-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="socialMedia mx-auto lg:mx-0 text-white">
            <Paragraph heading="Connect with us">
              <a href="https://www.facebook.com/profile.php?id=100086370857668">
                <p className="flex items-center justify-center md:justify-start text-white pb-6">
                  <FacebookIcon />
                  <span className="ml-2"> Facebook </span>
                </p>
              </a>
              <a href="https://mobile.twitter.com/amazecare">
                <p className="flex items-center justify-center md:justify-start text-white pb-6">
                  <TwitterIcon />
                  <span className="ml-2"> Twitter </span>
                </p>
              </a>
              <a href="https://www.instagram.com/invites/contact/?i=1i9nk0pax2v7a&utm_content=ppnn9wl">
                <p className="flex items-center justify-center md:justify-start text-white pb-6">
                  <InstagramIcon />
                  <span className="ml-2"> Instagram </span>
                </p>
              </a>
            </Paragraph>
          </div>
          <div className="links mx-auto lg:mx-0 text-white">
            <Paragraph heading="Useful Links">
              <p className="text-white">
                <a href="/resources" className="">Resources</a>
              </p>
              <p className="text-white">
                <a href="/careers" className="">Careers</a>
              </p>
              {/* <p className="text-white">
                <a href="#!" className="">Testimonials</a>
              </p> */}
              {/* <p className="text-white">
                <a href="/ns-govt-help" className="">Nova Scotia Govt. Help</a>
              </p> */}
            </Paragraph>
          </div>
          <div className="contact mx-auto lg:mx-0 text-white">
            <Paragraph heading="Contact us">
              <p className="flex items-center justify-center md:justify-start text-white">
                <EnvelopeIcon />
                <a href="mailto:info@amazecare.ca">info@amazecare.ca</a>
              </p>
              <p className="flex items-center justify-center md:justify-start text-white">
                <PhoneIcon />
                +1 902 541-7353
              </p>
              <p className="flex items-center justify-center md:justify-start text-white">
                <HomeIcon />
                (Bridgewater, NS)
              </p>
            </Paragraph>
          </div>
        </div>
      </div>
      {/* <div className="text-center p-6 bg-[#02565C] text-white">
        <span>© 2022 Copyright</span>
        <a className=" font-semibold" href="#."> AmazeCare</a>
      </div> */}
    </footer>
  )
}
export default Footer
