import React from 'react'
import Header from 'components/Header'
import HomeCareImage from 'assets/images/Services/HomeCare/HomeCare1.webp'
import Paragraph from 'components/common/paragraph';
import { FaArrowRight } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

export default function HomeCare() {
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Home Care Service in Nova Scotia - AmazeCare </title>
        <meta name="description" content="Our nurses can carefully assess your needs by giving importance to your lifestyle, daily routines, level of independence, likes, and dislikes so that you receive the care you need at your own home." />
        <meta name="keywords" content="Home Care" />
      </Helmet>
      <Header title="Home Care" />
      <section className="py-20 bg-white">
        <div className="container-layout text-center md:text-justify">
          <div className="flex flex-wrap items-center">
            <Paragraph>
              <p>
                Do you or your loved ones need personal care and assistance with activities of daily
                living? We can help you by carefully assessing your needs by giving importance to your
                lifestyle, daily routines, level of independence, likes, and dislikes so that you receive
                the care you need at your own home.
              </p>
            </Paragraph>
          </div>
        </div>
      </section>

      <section className="py-20">
        <div className="container-layout">
          <div className="flex flex-col lg:flex-row gap-10 items-center">
            <div className="flex-1">
              <p> With Amaze care, clients enjoy help with:: </p>
              <div className="flex items-baseline space-x-4 py-1">
                <div className="text-secondary">
                  <FaArrowRight />
                </div>
                <span> Cooking (meal and snack preparation) </span>
              </div>
              <div className="flex items-baseline space-x-4 py-1">
                <div className="text-secondary">
                  <FaArrowRight />
                </div>
                <span> Light housekeeping, dishes, changing the bed, laundry </span>
              </div>
              <div className="flex items-baseline space-x-4 py-1">
                <div className="text-secondary">
                  <FaArrowRight />
                </div>
                <span> Bathing, toileting, and dressing assistance </span>
              </div>
              <div className="flex items-baseline space-x-4 py-1">
                <div className="text-secondary">
                  <FaArrowRight />
                </div>
                <span> Walking, exercise, and mobility assistance </span>
              </div>
              <div className="flex items-baseline space-x-4 py-1">
                <div className="text-secondary">
                  <FaArrowRight />
                </div>
                <span> Updating the current status and providing feedback to the family </span>
              </div>
              <div className="flex items-baseline space-x-4 py-1">
                <div className="text-secondary">
                  <FaArrowRight />
                </div>
                <span> Medication administration and reminders  </span>
              </div>
              <p className="my-2 text-justify"> and more! </p>
            </div>
            <img
              alt=""
              src={HomeCareImage}
              className="flex-1 align-middle rounded-lg shadow-2xl"
            />
          </div>
        </div>
      </section>
    </main>
  );
}
