import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import RoutesInfo from 'config/routesInfo'

function App() {
  return (
    <div className="App bg-main">
      <BrowserRouter>
        <RoutesInfo />
      </BrowserRouter>
    </div>
  )
}

export default App
