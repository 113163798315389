import React from 'react'
import Header from 'components/Header';
import CareersForm from 'components/CareersForm';

function Careers() {
  return (
    <main>
      <Header title="Careers" />
      <section className="py-20 bg-tertiary">
        <div className="container-layout">
          <div className="text-center">
            <h1>
              Apply Today!
            </h1>
            <p>
              Please email
              <strong className="text-sky-600"> info@amazecare.ca </strong>
              if you are experiencing problems with our website&apos;s contact form.
            </p>
          </div>
          <CareersForm />
        </div>
      </section>
    </main>
  )
}

export default Careers
