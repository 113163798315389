import React from 'react';
import Header from 'components/Header';
import ContactForm from 'components/ContactForm';
import {
  FacebookIcon, TwitterIcon, InstagramIcon,
} from 'assets/icons/social-icons'
import { Helmet } from 'react-helmet';

function ContactUs() {
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Request for a free consultation - AmazeCare </title>
        <meta name="description" content="If you require a consultation or to book an appointment for our care services, speak to a nursing professional at AmazCare by filling the form on the contact us page or calling us." />
        <meta name="keywords" content="Contact AmazeCare" />
      </Helmet>
      <Header title="Contact Us" />
      <section className="py-20 bg-tertiary">
        <div className="container-layout">
          <div className="flex flex-wrap justify-between md:space-x-8">
            <div className="w-full mb-16 md:w-5/12">
              <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
                Have Questions?
              </h1>
              <p>
                Request for a free consultation by filling in your details below.
                Our Managers are available to answer your questions and help you make the best choice as per your health care needs.
              </p>
              <ContactForm />
            </div>

            <div className="w-full md:w-4/12">
              <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
                Contact us:
              </h1>
              <address>
                <b> Email Us at: </b>
                <br />
                <a href="mailto:info@amazecare.ca">info@amazecare.ca</a>
                <br />
                <br />
                <b> Contact number </b>
                <p> +1 902 541-7353</p>
                (Bridgewater, NS)
              </address>
              <div className="flex flex-col mt-8">
                <p>
                  <strong>Visit us at: </strong>
                </p>
                <div className="flex-1 flex">
                  <a href="https://www.facebook.com/profile.php?id=100086370857668" className="mr-6 text-primary">
                    <FacebookIcon />
                  </a>
                  <a href="https://mobile.twitter.com/amazecare" className="mr-6 text-primary">
                    <TwitterIcon />
                  </a>
                  <a href="https://www.instagram.com/invites/contact/?i=1i9nk0pax2v7a&utm_content=ppnn9wl" className="mr-6 text-primary">
                    <InstagramIcon />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default ContactUs
