import React from 'react'
import Header from 'components/Header'
import Paragraph from 'components/common/paragraph';
import { FaArrowRight } from 'react-icons/fa'
import RecreationImage from 'assets/images/Services/RecreationCare/Recreation2.webp'
import { Helmet } from 'react-helmet';

export default function RecreationCare() {
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Recreation Therapy in Nova Scotia - AmazeCare </title>
        <meta name="description" content="Our nurses can provide leisure activities if you or a loved one is experiencing loneliness and boredom to improve the quality of life." />
        <meta name="keywords" content="Therapeutic Recreation, recreation therapy" />
      </Helmet>
      <Header bgcolor="bg-white" title="Recreation Therapy" />
      <section className="py-20 bg-white text-center md:text-justify">
        <div className="container-layout">
          <div className="flex flex-col lg:flex-row gap-10 items-center">
            <div className="flex-1">
              <Paragraph>
                <p>
                  You can choose from a variety of recreation programs, if you or a loved one is experiencing loneliness and boredom.
                  Amaze Care offers leisure activities like:
                </p>
                <div className="my-2 mx-4 text-left">
                  <div className="flex items-baseline space-x-4 py-1">
                    <div className="text-secondary">
                      <FaArrowRight />
                    </div>
                    <span> Games such as Bingo, board games </span>
                  </div>
                  <div className="flex items-baseline space-x-4 py-1">
                    <div className="text-secondary">
                      <FaArrowRight />
                    </div>
                    <span>
                      Outings to community parks and centers
                    </span>
                  </div>
                  <div className="flex items-baseline space-x-4 py-1">
                    <div className="text-secondary">
                      <FaArrowRight />
                    </div>
                    <span> Walking </span>
                  </div>
                  <div className="flex items-baseline space-x-4 py-1">
                    <div className="text-secondary">
                      <FaArrowRight />
                    </div>
                    <span> 1:1 reading </span>
                  </div>
                  <div className="flex items-baseline space-x-4 py-1">
                    <div className="text-secondary">
                      <FaArrowRight />
                    </div>
                    <span>
                      Summer activities like gardening, spending time with nature, hiking
                    </span>
                  </div>
                  <div className="flex items-baseline space-x-4 py-1">
                    <div className="text-secondary">
                      <FaArrowRight />
                    </div>
                    <span> Exercise </span>
                  </div>
                  <div className="flex items-baseline space-x-4 py-1">
                    <div className="text-secondary">
                      <FaArrowRight />
                    </div>
                    <span> Music </span>
                  </div>
                  <div className="flex items-baseline space-x-4 py-1">
                    <div className="text-secondary">
                      <FaArrowRight />
                    </div>
                    <span> Art </span>
                  </div>
                </div>
                <p>Call us for more info.</p>
              </Paragraph>
            </div>
            <img
              alt=""
              src={RecreationImage}
              height={500}
              width={500}
              className="flex-1 align-middle rounded-lg shadow-2xl"
            />
          </div>
        </div>
      </section>
    </main>
  );
}
