import React from 'react'
import Header from 'components/Header';
import CareersForm from 'components/CareersForm';
import { Helmet } from 'react-helmet';
import image1 from 'assets/images/OurCaregiver/1.webp';
import image2 from 'assets/images/OurCaregiver/2.webp';
import image3 from 'assets/images/OurCaregiver/3.webp';

function OurCaregivers() {
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Join our growing team - AmazeCare </title>
        <meta name="description" content="Apply for the latest home care nurse jobs in Nova Scotia with AmazeCare. Verified employers. Competitive salary." />
        <meta name="keywords" content="Licensed practical nurse, continuing care assistants, registered nurses" />
      </Helmet>
      <Header title="Our Caregivers" />
      <section className="bg-white">
        <div className="container-layout">
          <div className="mx-auto p-10 w-full lg:w-1/2 text-center">
            <h1> Meet your Amazecare Team </h1>
            <p> Each client will have an experienced team of health care workers committed to provide the best possible care</p>
          </div>
          <div className="flex flex-wrap items-center justify-center">
            <div className="w-full md:w-6/12 lg:w-3/12 mb-6 px-6 sm:px-6 lg:px-4">
              <div className="flex flex-col">
                <a href=".#" className="mx-auto">
                  {/* <FaRegUser className="w-20 h-20 rounded-2xl drop-shadow-md hover:drop-shadow-xl transition-all duration-200 delay-100" /> */}
                  <img
                    alt=""
                    className="h-96 w-full rounded-2xl drop-shadow-md hover:drop-shadow-xl transition-all duration-200 delay-100"
                    src={image1}
                  />
                </a>

                <div className="text-center mt-6">
                  <h1 className="text-gray-900 text-xl font-bold mb-1 uppercase">
                    Care Coordinator
                  </h1>

                  {/* <div className="text-gray-700 font-light mb-2">
                    DIRECTOR
                  </div> */}
                </div>
              </div>
            </div>
            <div className="w-full md:w-6/12 lg:w-3/12 mb-6 px-6 sm:px-6 lg:px-4">
              <div className="flex flex-col">
                <a href=".#" className="mx-auto">
                  {/* <FaRegUser className="w-20 h-20 rounded-2xl drop-shadow-md hover:drop-shadow-xl transition-all duration-200 delay-100" /> */}
                  <img
                    alt=""
                    className="h-96 w-full rounded-2xl drop-shadow-md hover:drop-shadow-xl transition-all duration-200 delay-100"
                    src={image2}
                  />
                </a>

                <div className="text-center mt-6">
                  <h1 className="text-gray-900 text-xl font-bold mb-1 uppercase">
                    Care Advisor
                  </h1>

                  {/* <div className="text-gray-700 font-light mb-2">
                    DIRECTOR
                  </div> */}
                </div>
              </div>
            </div>
            <div className="w-full md:w-6/12 lg:w-3/12 mb-6 px-6 sm:px-6 lg:px-4">
              <div className="flex flex-col">
                <a href=".#" className="mx-auto">
                  <img
                    alt=""
                    className="h-96 w-full rounded-2xl drop-shadow-md hover:drop-shadow-xl transition-all duration-200 delay-100"
                    src={image3}
                  />
                </a>

                <div className="text-center mt-6">
                  <h1 className="text-gray-900 text-xl font-bold mb-1 uppercase">
                    Care Giver
                  </h1>

                  {/* <div className="text-gray-700 font-light mb-2">
                    NURSE MANAGER
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-20 bg-tertiary">
        <div className="container-layout">
          <div className="text-center">
            <h1>
              Apply Today!
            </h1>
            <p>
              Please email
              <strong className="text-sky-600"> info@amazecare.ca </strong>
              if you are experiencing problems with our website&apos;s contact form.
            </p>
          </div>
          <CareersForm />
        </div>
      </section>
    </main>
  )
}

export default OurCaregivers
