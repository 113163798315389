import React from 'react'
import Header from 'components/Header'
import Paragraph from 'components/common/paragraph'
import { FaArrowRight } from 'react-icons/fa'
import ParkinsonCareImage from 'assets/images/Services/ParkinsonCare/ParkinsonCare.webp'
import { Helmet } from 'react-helmet';

export default function ParkinsonCare() {
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Parkinson&apos;s Care in Nova Scotia - AmazeCare </title>
        <meta name="description" content="The nurses at AmazeCare are specially trained to assist patients with Parkinson's disease in a safe and comfortable environment." />
        <meta name="keywords" content="Parkinson’s Care" />
      </Helmet>
      <Header bgcolor="bg-white" title="Parkinson's Care" />
      <section className="py-20 bg-white text-center md:text-justify">
        <div className="container-layout">
          <div className="flex flex-col lg:flex-row gap-10 items-center">
            <div className="flex-1">
              <Paragraph>
                <p>
                  Parkinson&apos;s is a neurological illness that worsens with time.
                  Tremors, rigidity, slowness of movement, unsteadiness of posture, and unsteadiness of balance are the major symptoms.
                  Although the disease affects each patient differently, the majority of Parkinson&apos;s sufferers
                  can no longer perform their daily chores without assistance:
                </p>
                <div className="my-2 mx-4 text-left">
                  <p> Amaze care can provide the following:</p>
                  <div className="flex items-baseline space-x-4 py-1">
                    <div className="text-secondary">
                      <FaArrowRight />
                    </div>
                    <span> A unique and thorough care strategy to guarantee the satisfaction of the client&apos;s needs</span>
                  </div>
                  <div className="flex items-baseline space-x-4 py-1">
                    <div className="text-secondary">
                      <FaArrowRight />
                    </div>
                    <span> Consistent 24/7 surveillance is a possibility to prevent falls </span>
                  </div>
                  <div className="flex items-baseline space-x-4 py-1">
                    <div className="text-secondary">
                      <FaArrowRight />
                    </div>
                    <span> Companionship, assistance and support with daily tasks </span>
                  </div>
                  <div className="flex items-baseline space-x-4 py-1">
                    <div className="text-secondary">
                      <FaArrowRight />
                    </div>
                    <span> Establishing a secure and encouraging environment that encourages independence </span>
                  </div>
                </div>
              </Paragraph>
            </div>
            <img
              alt=""
              src={ParkinsonCareImage}
              height={500}
              width={500}
              className="flex-1 align-middle rounded-lg shadow-2xl"
            />
          </div>
        </div>
      </section>
      <section className="py-20 text-center md:text-justify">
        <div className="container-layout">
          <div className="flex flex-col">
            <Paragraph heading="Round-the-clock care">
              <p>
                Our caregivers can provide solace and support if your loved one suffers from Parkinson&apos;s disease and is unable to take care of themselves.
                We will analyse the patient and contact relevant departments or doctors to design a better care plan with the help of the client&apos;s comments.
                Our highly trained carers and nurses are able to deliver quality care.
              </p>
              <p>
                Every day of the week, 24/7, we provide our services.
                The level of care that your loved one requires is what we provide.
                Our caregivers have highly flexible schedules and are available right away.
              </p>
              <p>
                Call to discuss a personalized care plan for your loved one with a Care Manager for dependable Parkinson&apos;s care.
              </p>
            </Paragraph>
          </div>
        </div>
      </section>
    </main>
  );
}
