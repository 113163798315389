import React from 'react';
import Header from 'components/Header';
import Paragraph from 'components/common/paragraph';
import AboutUsImageOne from 'assets/images/AboutUS/AboutUs1.webp'
import { Helmet } from 'react-helmet';

function AboutUs() {
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Quality Nursing Care Services - AmazeCare </title>
        <meta name="description" content="At AmazeCare, we focus on a client-centered approach to achieve positive results. Our highly qualified and experienced staff provides seniors with the best possible care in Nova Scotia. Choose AmazeCare for considerate, compassionate, and committed support." />
        <meta name="keywords" content="Licensed practical nurse" />
      </Helmet>
      <Header title="About Us" />
      <section className="container-layout py-10">
        <h1 className="text-center md:text-left md:w-1/2">
          Our main mission is to provide quality nursing care with a
          client-centered approach to achieve positive outcomes.
        </h1>
      </section>

      <section className="py-20 bg-white">
        <div className="container-layout">
          <div className="flex flex-wrap items-center justify-between md:space-x-8">
            <div className="w-full md:flex-[0.5] py-4">
              <img
                alt="aboutusone"
                className="mx-auto max-w-full h-[500px] rounded-lg shadow-lg"
                src={AboutUsImageOne}
              />
            </div>
            <div className="w-full md:flex-1 text-center md:text-justify">
              <Paragraph heading="Our hiring process">
                <p>
                  Our employment criteria are well-designed to build a team of caring,
                  compassionate, and qualified professionals. Criminal background check/vulnerable sector check,
                  references are taken into consideration.
                </p>
              </Paragraph>
              <Paragraph heading="The Client and Caregiver Team">
                <p>
                  We ensure we have the right caregiver for you depending on your healthcare needs,
                  as some are more knowledgeable and certified in specific fields.
                </p>
              </Paragraph>
              <Paragraph heading="Mandatory training and education">
                <p>
                  At Amaze Care, we invest our time and effort to keep our employees up-to-date with training and education,
                  including but not limited to dementia, Alzheimer&apos;s, Parkinson&apos;s, stroke, and post-hospitalization care.
                </p>
              </Paragraph>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default AboutUs
