import React from 'react';
import { useForm } from 'react-hook-form';
import firebase from '../../firebase';

function CareersForm() {
  const {
    register, handleSubmit, reset, formState: { errors },
  } = useForm();

  const [isAlertVisible, setIsAlertVisible] = React.useState(false);
  const [fileBase64, setFileBase64] = React.useState('');

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const base64String = event.target.result.split(',')[1];
        setFileBase64(base64String);
      };

      reader.readAsDataURL(file);
    } else {
      setFileBase64('');
    }
  };

  const onSubmit = async (data) => {
    setIsAlertVisible(true);
    setTimeout(() => {
      setIsAlertVisible(false);
    }, 5000);

    try {
      /* The code block is using Firebase Cloud Functions to make an HTTP request to the
      'submitApplication' function. */
      const submitApplication = firebase.functions().httpsCallable('submitApplication');
      const response = await submitApplication({
        name: data.name,
        email: data.email,
        message: data.message,
        attachment: {
          name: data.attachment[0].name,
          type: data.attachment[0].type,
          data: fileBase64,
        },
      });

      /* The code block is checking if the `response` object exists and if it has a `data` property. If
      both conditions are true, it means that the response is in the expected format. In that case,
      it logs the `response.data` to the console and resets the form using the `reset()` function. */
      if (response && response.data) {
        console.log(response.data);
        reset(); // Reset the form after successful submission
      } else {
        console.error('Invalid response format:', response);
      }
    } catch (error) {
      console.error('Error submitting job application:', error);
    }
  }

  return (
    <form className="flex flex-col mx-auto bg-white shadow-md rounded p-10 lg:w-1/2" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
      <label htmlFor="name">
        <div> NAME </div>
        <input {...register('name', { required: true })} />
        {errors.name && <span>This field is required</span>}
      </label>

      <label className="flex-1" htmlFor="email">
        <div> EMAIL ADDRESS </div>
        <input {...register('email', { required: true })} />
        {errors.email && <span>This field is required</span>}
      </label>

      <label className="flex-1" htmlFor="file">
        <div> UPLOAD RESUME </div>
        <input type="file" {...register('attachment', { required: true })} onChange={handleFileChange} />
        {errors.file && <span>This field is required</span>}
      </label>

      <label htmlFor="message">
        <div> Message </div>
        <textarea rows="4" {...register('message')} />
      </label>
      <input
        type="submit"
        className="text-white bg-primary hover:bg-secondary focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0"
      />
      {isAlertVisible && (
        <div className="p-4 m-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800" role="alert">
          <span className="font-medium">Success alert!! </span>
          we will get in touch with you shortly
        </div>
      )}
    </form>
  )
}

export default CareersForm
