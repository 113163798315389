import React from 'react'
import Header from 'components/Header'
import Paragraph from 'components/common/paragraph'
import Respitecare from 'assets/images/Services/RespiteCare/RespiteCare.webp'
import { Helmet } from 'react-helmet';
// import Features from 'components/common/features';

// import { BiHealth } from 'react-icons/bi';
// import {
//   FaBuromobelexperte, FaClock, FaHospitalUser,
// } from 'react-icons/fa'

export default function RespiteHomeCare() {
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Respite Care in Nova Scotia - AmazeCare </title>
        <meta name="description" content="Nurses at AmazeCare provide respite care to give you a temporary break from caregiving. We anticipate your needs and make sure you're comfortable and supported." />
        <meta name="keywords" content="Respite Care" />
      </Helmet>
      <Header bgcolor="bg-white" title="Respite Care" />
      <section className="py-20 bg-white text-center md:text-justify">
        <div className="container-layout">
          <div className="flex flex-col lg:flex-row gap-10 items-center">
            <div className="flex-1">
              <Paragraph>
                <p>
                  Families who take care of clients sometimes need a break while they are overwhelmed or have to run errands.
                  We realize that it&apos;s hard to balance everything at once.
                </p>
                <p>
                  By providing respite care to you or your loved one, it helps sustain family caregiver&apos;s health and wellness,
                  avoid or delay placements, and reduces abuse and neglect.
                </p>
                <p>
                  Our Care team anticipates clients needs, and provides respite care which includes a whole care package that replaces
                  the care provided by the family member.
                </p>
              </Paragraph>
            </div>
            <img
              alt=""
              src={Respitecare}
              height={500}
              width={500}
              className="flex-1 align-middle rounded-lg shadow-2xl"
            />
          </div>
        </div>
      </section>
      {/* <section className="py-20 text-center md:text-justify">
        <div className="container-layout">
          <div className="flex flex-col">
            <Paragraph heading="Professional care and services">
              <p>
                Our services will have the quality that you look for, our substitute care service means
                that we will take care of everything from appointments to paying bills and so on.
              </p>
              <p>
                Our care team anticipates your parent&apos;s needs, whether it&apos;s for healthcare or other home-related issues.
              </p>
              <p>
                You want your parents to enjoy their home as long as possible,
                but it&apos;s getting difficult day by day for you to give them the support they need.
                There are appointments to go to, hospital visits,
                grocery shopping and bill payments, and home repairs that can&apos;t wait any longer! And that&apos;s just the start of it.
              </p>
            </Paragraph>
          </div>
        </div>
      </section> */}
      {/* <section className="py-20 bg-tertiary text-center md:text-justify">
        <div className="container-layout">
          <div className="flex flex-col text-left">
            <Paragraph heading="Quality time with your loved ones">
              <p>
                Let us take care of all the daily tasks and details of managing the home.
                You can have peace of mind knowing that your parents are safe and living well with someone by their side
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <Features
                  icon={<BiHealth />}
                  heading="A care team"
                  content="with a registered nurse dedicated to your parent's healthcare needs.
                  We can book appointments, and accompany your loved-one to the doctor or dentist,
                  so you don&apos;t have to take time off work and will get regular updates from our team."
                />
                <Features
                  icon={<FaBuromobelexperte />}
                  heading="Home maintenance"
                  content="Our team makes sure that everything is functioning as it should in the home.
                  We can take care of booking and supervising the repairs for you.
                  We also help with technology such as home alarms, TVs and even setting up Zoom calls or any other applications"
                />
                <Features
                  icon={<FaClock />}
                  heading="Outings and errands"
                  content="Our caregivers are trained to accompany your loved ones even if they have limited mobility.
                  We coordinate personal activities including social outings, errands, and appointments."
                />
                <Features
                  icon={<FaHospitalUser />}
                  heading="On-call for emergencies."
                  content="You are out of town and anxious that you can&apos;t help your parents in an emergency?
                  We are here 24/7 to respond to any emergency - medical or otherwise - so that you can have peace of mind."
                />
              </div>
            </Paragraph>
          </div>
        </div>
      </section> */}
    </main>
  );
}
