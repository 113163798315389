import React from 'react'
import mainImage from 'assets/images/HomePage/doctor.webp'
import serviceImage from 'assets/images/HomePage/services.webp'
import {
  FaClock, FaFileContract, FaCheckCircle, FaBuromobelexperte, FaBookMedical, FaProjectDiagram,
} from 'react-icons/fa'
import Features from 'components/common/features';
import { Helmet } from 'react-helmet';

function HomePage() {
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Home Health Care Services - AmazeCare </title>
        <meta name="description" content="Providing Nursing Care Services to seniors in Nova Scotia. Licensed Practical Nurses, and registered nurses, work tirelessly with clients to meet their healthcare needs to achieve positive client outcomes." />
        <meta name="keywords" content="Home Care, Nursing " />
      </Helmet>
      <section className="px-4 sm:px-6 lg:px-8 bg-main">
        <div className="container-layout flex flex-col lg:flex-row items-center">
          <div className="text-center lg:text-left flex-1 pt-6 lg:pt-16 lg:pr-4">
            <h2> Dedicated to excellence and compassion for clients.</h2>
            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:mx-auto md:mt-5 md:text-xl lg:mx-0 max-w-3xl">
              Our team at Amaze Care consists of qualified healthcare professionals, including continuing care assistants,
              Licensed Practical Nurses, and registered nurses, who work tirelessly with clients to meet their healthcare needs.
              We focus on maintaining the quality of life to achieve positive client outcomes in their comfort zone,
              which they call &quot;Home&quot;.
            </p>
            <div className="m-auto lg:m-0 w-2/3 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
              <div className="rounded-md shadow">
                <a href="/contact-us" className="button-link">
                  Book Now
                </a>
              </div>
            </div>
          </div>
          <img
            className="homepage-image block mx-auto my-4 lg:my-0 sm:shrink-0 object-cover pt-10"
            src={mainImage}
            width={500}
            height={500}
            alt="Woman's Face"
          />
        </div>
      </section>

      <section className="py-20 bg-white">
        <div className="container-layout mx-auto p-4 sm:p-6 lg:p-8">
          <div className="text-center p-14">
            <h2> Why Choose Amaze Care? </h2>
            <p>
              We strive to provide the best possible service out there. Here are a few reasons,
              among many others.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-6 gap-8">
            <Features
              className="space-y-2 md:col-span-2 rounded shadow bg-tertiary p-10"
              icon={<FaClock />}
              heading="Available 24/7"
              content="Our team is available round the clock, including days, evenings, nights, and weekends."
            />
            <Features
              className="space-y-2 md:col-span-2 rounded shadow bg-tertiary p-10"
              icon={<FaFileContract />}
              heading="Flexible Contracts"
              content="Our contracts are flexible according to the needs of our clients."
            />
            <Features
              className="space-y-2 md:col-span-2 rounded shadow bg-tertiary p-10"
              icon={<FaBuromobelexperte />}
              heading="Continuing Care Experts"
              content="We specialize in providing care based on our client's health conditions."
            />
            <Features
              className="space-y-2 md:col-span-3 rounded shadow bg-tertiary p-10"
              icon={<FaBookMedical />}
              heading="Client Centered Care"
              content="Our unique approach to care promotes a healthy mind, body, and spirit."
            />
            <Features
              className="space-y-2 md:col-span-3 rounded shadow bg-tertiary p-10"
              icon={<FaProjectDiagram />}
              heading="Collaborative care"
              content="We&apos;re honoured to be connected with qualified individuals to get relevant
              information and even one on one care."
            />
          </div>
        </div>
      </section>

      <section className="bg-main">
        <div className="flex flex-col lg:flex-row flex-wrap items-center justify-between md:space-x-8">
          <div className="flex-1">
            <img
              className="block mx-auto my-4 lg:my-0 sm:shrink-0 object-cover flex-1"
              src={serviceImage}
              alt="Woman's Face"
            />
          </div>
          <div className="flex-1 lg:text-left p-20 lg:pr-20">
            <h3> Right at your doorstep, providing a range of in-home care options for seniors and adults. </h3>
            <div className="flex flex-col lg:flex-row ">
              <div className="flex-1">
                <a href="/home-care" className="flex items-center space-x-4 pb-5">
                  <FaCheckCircle />
                  <span> Home Care </span>
                </a>
                <a href="/recreation-therapy" className="flex items-center space-x-4 pb-5">
                  <FaCheckCircle />
                  <span>  Recreation Therapy</span>
                </a>
                <a href className="flex items-center space-x-4 pb-5">
                  <FaCheckCircle />
                  <span> Respite Care </span>
                </a>
                <a href="/parkinson-care" className="flex items-center space-x-4 pb-5">
                  <FaCheckCircle />
                  <span> Parkinson&apos;s Care </span>
                </a>
              </div>
              <div className="flex-1">
                <a href="/stroke-care" className="flex items-center space-x-4 pb-5">
                  <FaCheckCircle />
                  <span> Stroke Care </span>
                </a>
                <a href="/cancer-care" className="flex items-center space-x-4 pb-5">
                  <FaCheckCircle />
                  <span> Cancer Care </span>
                </a>
                <a href="/palliative-care" className="flex items-center space-x-4 pb-5">
                  <FaCheckCircle />
                  <span> Palliative Care </span>
                </a>
                <a href="/hospital-care" className="flex items-center space-x-4 pb-5">
                  <FaCheckCircle />
                  <span> Hospital Care </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default HomePage
