import PropTypes from 'prop-types'
import React from 'react';

export default function Features({
  icon, image, heading, content, className,
}) {
  return (
    <div className={className}>
      {image && (
      <img
        className="image-radius-right"
        src={image}
        alt="Woman's Face"
      />
      )}
      <div className="flex items-center gap-2">
        {icon && <div className="text-primary text-4xl">{icon}</div> }
        <h3 className="m-0">{heading}</h3>
      </div>
      <p>{content}</p>
    </div>
  )
}

Features.propTypes = {
  content: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  icon: PropTypes.string,
  image: PropTypes.string,
}

Features.defaultProps = {
  icon: '',
  image: '',
}
