import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import { format } from 'date-fns'
import firebase from '../../firebase';

function ContactForm() {
  const {
    register, handleSubmit, control, reset, formState: { errors },
  } = useForm();

  const [isAlertVisible, setIsAlertVisible] = React.useState(false);

  const onSubmit = async (data) => {
    // const emaildata = {
    //   name: formData.name,
    //   email: formData.email,
    //   contact: formData.contact,
    //   address: formData.address,
    //   preference: formData.preference,
    //   care: formData.care,
    //   aboutus: formData.aboutus,
    //   date: format(new Date(formData.date), 'MM/dd/yyyy'),
    //   startTime: format(new Date(formData.startTime), 'pp'),
    //   special: formData.special,
    // };

    setIsAlertVisible(true);
    setTimeout(() => {
      setIsAlertVisible(false);
    }, 5000);

    try {
      const sendContactEMail = firebase.functions().httpsCallable('sendContactEMail');
      const response = await sendContactEMail(data);

      // Check if response.data exists and handle it accordingly
      if (response && response.data) {
        console.log(response.data);
        reset(); // Reset the form after successful submission
      } else {
        console.error('Invalid response format:', response);
      }
    } catch (error) {
      console.error('Error sending the email:', error);
    }
  }

  return (
    <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
      <label htmlFor="name">
        <div> NAME </div>
        <input {...register('name', { required: true })} />
        {errors.name && <span>This field is required</span>}
      </label>

      <label className="flex-1" htmlFor="email">
        <div> EMAIL ADDRESS </div>
        <input {...register('email', { required: true })} />
        {errors.email && <span>This field is required</span>}
      </label>

      <label className="flex-1" htmlFor="contact">
        <div> Contact Number </div>
        <input
          type="tel"
          {...register('contact', { required: true })}
        />
        {errors.contact && <span>This field is required</span>}
      </label>

      <label htmlFor="address">
        <div> ADDRESS </div>
        <input {...register('address', { required: true })} />
        {errors.address && <span>This field is required</span>}
      </label>

      <label htmlFor="preference">
        <div> PREFFERED METHOD OF CONTACT </div>
        <select className="py-3" {...register('preference', { required: true })}>
          <option value="call">Call</option>
          <option value="email">Email</option>
        </select>
        {errors.preference && <span>This field is required</span>}
      </label>

      <label htmlFor="care">
        <div> TYPE OF CARE </div>
        <input {...register('care', { required: true })} />
        {errors.care && <span>This field is required</span>}
      </label>

      <label htmlFor="aboutus">
        <div> HOW DID YOU HEAR ABOUT US </div>
        <input {...register('aboutus', { required: true })} />
        {errors.aboutus && <span>This field is required</span>}
      </label>

      <label htmlFor="meeting-time">
        <div className="uppercase"> Choose a time for your appointment: </div>
        <br />
        <div className="flex flex-col lg:flex-row gap-2">
          <label htmlFor="date">
            <div> DATE </div>
            <Controller
              control={control}
              name="date"
              render={({
                field: {
                  onChange, onBlur, value,
                },
              }) => (
                <ReactDatePicker
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                />
              )}
            />
            {errors.date && <span>This field is required</span>}
          </label>
          <label htmlFor="startTime">
            <div> TIME </div>
            <Controller
              control={control}
              name="startTime"
              render={({
                field: {
                  onChange, onBlur, value,
                },
              }) => (
                <ReactDatePicker
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                />
              )}
            />
            {errors.startTime && <span>This field is required</span>}
          </label>
        </div>
      </label>

      <label htmlFor="special">
        <div> Special Instructions </div>
        <textarea rows="5" {...register('special')} />
      </label>
      <input
        type="submit"
        className="text-white bg-primary hover:bg-secondary focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0"
      />
      {isAlertVisible && (
        <div className="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800" role="alert">
          <span className="font-medium">Success alert!! </span>
          we will get in touch with you shortly
        </div>
      )}
    </form>
  )
}

export default ContactForm
