import React from 'react'
import Header from 'components/Header'
import HospitalCareImage1 from 'assets/images/Services/HospitalCare/HospitalCare1.webp'
import HospitalCareImage2 from 'assets/images/Services/HospitalCare/HospitalCare2.webp'
import Paragraph from 'components/common/paragraph'
import { Helmet } from 'react-helmet';

export default function HospitalCare() {
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Hospital Care in Nova Scotia - AmazeCare </title>
        <meta name="description" content="A skilled staff member from AmazeCare is available to be with your loved one while they are in the hospital. Your loved one will feel more secure knowing that someone is there for them at all times." />
        <meta name="keywords" content="Hospital Care" />
      </Helmet>
      <Header title="Hospital Care" />
      <section className="py-20 bg-white text-center md:text-justify">
        <div className="container-layout">
          <div className="flex flex-col lg:flex-row gap-10 items-center">
            <div className="flex-1">
              <Paragraph>
                <p>
                  Being admitted to the hospital, whether for a condition or an emergency, may be quite upsetting for the patient and their family.
                  Family members frequently experience tensions due to their busy schedules and the need to be at the hospital with a loved one.
                </p>
                <p>
                  A skilled staff member from Amaze Care is available to be with your loved one while they are in the hospital.
                  Your loved one will feel more secure knowing that someone is there for them at all times if there is a caregiver there who has been assigned particularly to care for them.
                  The patient will sense calmness as a result, which will lessen anxiety and speed up their recovery.
                </p>
                <p>
                  We provide care at the hospital by delivering all the essentials and staying with the
                  client during the hospital stay, following the client after discharge and will accompany the client
                  to his home/facility.
                </p>
              </Paragraph>
            </div>
            <img
              alt=""
              height={500}
              width={500}
              src={HospitalCareImage1}
              className="flex-1 align-middle rounded-lg shadow-2xl my-5"
            />
          </div>
        </div>
      </section>
      <section className="py-20 text-center md:text-justify">
        <div className="container-layout">
          <div className="flex flex-col lg:flex-row gap-10 items-center">
            <img
              alt=""
              height={500}
              width={500}
              src={HospitalCareImage2}
              className="flex-1 align-middle rounded-lg shadow-2xl my-5"
            />
            <div className="flex-1">
              <Paragraph>
                <p>
                  You receive the care you require when you require it thanks to the flexibility of our services.
                  Our carers are accessible everywhere and at any time.
                  We can be there even at very short notice because we are aware that hospital
                  admissions and discharges can occasionally occur without warning.
                  To discuss a Hospital or Post-Hospital Care plan that can make hospital
                  admission and post-hospital recovery a more comfortable experience,
                  contact a Care Manager right away.
                </p>
              </Paragraph>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
